export default {
  name: 'ImageBlock',
  props: {
    mobileImage: {
      type: [Object, String],
      required: false,
      default: null
    },
    desktopImage: {
      type: [Object, String],
      required: false,
      default: null
    },
    link: {
      type: [Object, String],
      required: false,
      default: null
    },
    title: {
      type: String,
      required: false,
      default: null
    },
    caption: {
      type: String,
      required: false,
      default: ''
    },
    /**
     * Image display sizes for optimized images
     * Can be sent from a parent, ie the grid component
     * Defaults to 100vw if not sent
     */
    mobileDisplaySize: {
      type: String,
      required: false,
      default: '100vw'
    },
    desktopDisplaySize: {
      type: String,
      required: false,
      default: '100vw'
    },
    mobileIndent: {
      type: String,
      default: '100vw'
    }
  },
  computed: {
    desktopSrc () {
      // return moible image if desktop isnt set to assure image size optimization for both a mobile an desktop
      if (!this.desktopImage?.filename && !!this.mobileImage?.filename) { return this.mobileImage.filename }
      return this.desktopImage.filename
    },
    mobileSrc () {
      // return desktop image if mobile isnt set to assure image size optimization for both a mobile an desktop
      if (!this.mobileImage && !!this.desktopImage) { return this.desktopImage.filename }
      return this.mobileImage.filename
    },
    mobileIndentClass () {
      return {
        left: 'ml-16',
        right: 'mr-16'
      }[this.mobileIndent]
    }
  }
}
